export default function Blood() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.6984 19.1682C12.6948 19.7227 12 20 12 20C12 20 3 16.4084 3 9.22528C3 3.83795 9 2.40324 12 5.92259C14.6494 2.81454 19.6385 3.57026 20.7685 7.5"
        stroke="#096467"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M22 15.85C22 17.5897 20.6569 19 19 19C17.3431 19 16 17.5897 16 15.85C16 12.25 19 10 19 10C19 10 22 12.25 22 15.85Z"
        fill="#096467"
      />
    </svg>
  );
}
