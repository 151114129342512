import { AbnormalDetails } from "@/types/AbnormalDetails";
import RibbonImgLarge from "../assets/img/ribbon-blue-large.png";
import SpinnerImg from "../assets/img/spinner.gif";
import PhotoOne from "../assets/img/photo_one_urine_16x9.jpg";
import PhotoThree from "../assets/img/photo_three_black_area.jpg";
import PhotoFour from "../assets/img/pre-scan-image.jpg";
import {
  PrefixType,
  SuffixType,
  NumericMeasurmentType,
  NumericMeasurmentUnitsType,
} from "@/types/SuffixType";
import Kidneys from "@/components/icons/Kidneys";
import Liver from "@/components/icons/Liver";
import Glucose from "@/components/icons/Glucose";
import Infection from "@/components/icons/Infection";
import Hydration from "@/components/icons/Hydration";
import PHIcon from "@/components/icons/PH";
import BloodIcon from "@/components/icons/Blood";
import KetoneIcon from "@/components/icons/Ketone";

export const QUERY_KEY = {
  scanResults: "scanResults",
};

export const SERVER_URL = process.env.REACT_APP_ALGO_API;
export const ORDER_TEST_KITS_URL =
  "https://ribboncheckup.com/pages/select-your-kit?utm_source=app";

export enum EResultStatusLabel {
  Normal = "Normal",
  Abnormal = "Abnormal",
  WithinRange = "Within Range",
  NoIndication = "No Indication",
  Detected = "Detected",
}

const DETAIL = [
  "leu",
  "nit",
  "uro",
  "pro",
  "ph",
  "blo",
  "sg",
  "ket",
  "bil",
  "glu",
];

export const PRIMARY_USE_OPTIONS = [
  "Kidney",
  "Liver",
  "Heart",
  "UTI",
  "General Health",
  "Auto-Immune Diseases",
];

export const PARAMETER_TITLE: { [key: string]: string } = {
  leu: "Leukocytes",
  nit: "Nitrite",
  uro: "Urobilinogen",
  pro: "Protein",
  ph: "pH",
  blo: "Blood",
  sg: "Specific Gravity",
  ket: "Ketone",
  bil: "Bilirubin",
  glu: "Glucose",
};

const NUMERIC_MEASUREMENT: NumericMeasurmentType = {
  leu: ["0", "15", "70", "125", "500"],
  nit: ["Negative", "Positive", "Positive"],
  uro: [".2", "1", "2", "4", "8", "12"],
  pro: ["0", "15", "30", "100", "300", "2000"],
  ph: ["5.0", "6.0", "6.5", "7.0", "7.5", "8.0", "8.5"],
  blo: ["0", "10", "25", "80", "100"],
  sg: ["1.000", "1.005", "1.010", "1.015", "1.020", "1.025", "1.030"],
  ket: ["0", "5", "15", "40", "80", "160"],
  bil: ["0", "1", "2", "4"],
  glu: ["0", "100", "250", "500", "1000", "2000"],
};
const NUMERIC_MEASUREMENT_UNITS: NumericMeasurmentUnitsType = {
  leu: "leu/μL",
  nit: "",
  uro: "mg/dL",
  pro: "mg/dL",
  ph: "",
  blo: "Ery/μL",
  sg: "",
  ket: "mg/dL",
  bil: "mg/dL",
  glu: "mg/dL",
};

const PREFIX: PrefixType = {
  leu: ["Negative", "Trace", "Small", "Moderate", "Large"],
  nit: ["Negative", "Positive", "Positive"],
  uro: ["Normal", "Normal", "Small", "Moderate", "Large"],
  pro: ["Negative", "Trace", "Small", "Moderate", "Large", "Large"],
  ph: ["5.0", "6.0", "6.5", "7.0", "7.5", "8.0", "8.5"],
  blo: ["Negative", "Trace", "Small", "Moderate", "Large"],
  sg: [
    "Very Hydrated",
    "Hydrated",
    "Hydrated",
    "Hydrated",
    "Hydrated",
    "Hydrated",
    "Dehydrated",
  ],
  ket: ["Negative", "Trace", "15", "40", "80", "160"],
  bil: ["Negative", "Small", "Moderate", "Large"],
  glu: ["Negative", "100", "250", "500", "1000", "2000"],
};

const SUFFIX: SuffixType = {
  leu: ["", "", "+", "++", "+++"],
  nit: ["", "+", "+"],
  uro: ["", "", "+", "++", "+++", "++++"],
  pro: ["", "", "+", "++", "+++", "++++"],
  ph: ["", "", "", "", "", "", ""],
  blo: ["", "", "+", "++", "+++"],
  sg: ["", "", "", "", "", "", ""],
  ket: ["", "", "+", "++", "+++", "++++"],
  bil: ["", "+", "++", "+++"],
  glu: ["", "", "+", "++", "+++", "++++"],
};

const ABNORMAL_DETAILS: AbnormalDetails = {
  leu: "Indicates a possible urinary tract infection or inflammation.",
  nit: "Suggests the presence of certain bacteria that can cause urinary tract infections.",
  uro: "Elevated levels may indicate liver problems or hemolytic anemia.",
  pro: "Presence may suggest kidney disease, urinary tract infections, or other disorders.",
  ph: "A urine pH level between 4.5 and 8.0 is considered normal. A pH of 8.5 or higher could indicate kidney problems, urinary tract infections, or certain metabolic conditions.",
  blo: "Presence may indicate kidney or urinary tract problems, infections, or certain cancers.",
  sg: "Abnormal levels suggest the body is dehydrated or that the kidney's concentrating ability is off.",
  ket: "Presence may indicate uncontrolled diabetes, starvation, or a high-fat, low-carbohydrate diet.",
  bil: "Elevated levels suggest liver problems, bile duct obstruction, or certain blood disorders.",
  glu: "Presence may indicate diabetes, kidney problems, or other metabolic disorders.",
};

export const images = [
  RibbonImgLarge,
  PhotoOne,
  PhotoThree,
  PhotoFour,
  SpinnerImg,
];

export const STATE_LIST = [
  {
    key: "kidney",
    title: "Kidneys",
    parameters: ["pro"],
    Icon: Kidneys,
    statusLabels: {
      normal: EResultStatusLabel.WithinRange,
      abnormal: EResultStatusLabel.Abnormal,
    },
  },
  {
    key: "liver",
    title: "Liver ",
    parameters: ["bil", "uro"],
    Icon: Liver,
  },
  {
    key: "diabetes",
    title: "Glucose",
    parameters: ["glu"],
    Icon: Glucose,
  },
  {
    key: "infection",
    title: "Infection",
    parameters: ["leu", "nit"],
    Icon: Infection,
    statusLabels: {
      normal: EResultStatusLabel.NoIndication,
      abnormal: EResultStatusLabel.Detected,
    },
  },
  {
    key: "hydration",
    title: "Hydration",
    parameters: ["sg"],
    Icon: Hydration,
  },
  { key: "ph", title: "pH", parameters: ["ph"], Icon: PHIcon },
  {
    key: "blood",
    title: "Blood",
    parameters: ["blo"],
    Icon: BloodIcon,
  },
  {
    key: "ketone",
    title: "Ketone",
    parameters: ["ket"],
    Icon: KetoneIcon,
  },
];

export const INITIAL_USER_DATA = {
  leu: { title: "White Blood Cell", value: 1, color: [0, 0, 0] },
  nit: { title: "Bacteria Test", value: 1, color: [0, 0, 0] },
  uro: { title: "1st Liver Test", value: 1, color: [0, 0, 0] },
  pro: { title: "1st Kidney Test", value: 1, color: [0, 0, 0] },
  ph: { title: "pH Test", value: 1, color: [0, 0, 0] },
  blo: { title: "2nd Kidney Test", value: 1, color: [0, 0, 0] },
  sg: { title: "Hydration Test", value: 1, color: [0, 0, 0] },
  ket: { title: "1st Glucose Test", value: 1, color: [0, 0, 0] },
  bil: { title: "2nd Liver Test", value: 1, color: [0, 0, 0] },
  glu: { title: "2nd Glucose Test", value: 1, color: [0, 0, 0] },
};

export const INITIAL_DOCTOR_DATA = {
  leu: { title: "Leukocytes", value: "0", color: [0, 0, 0], prediction: 0 },
  nit: { title: "Nitrite", value: "0", color: [0, 0, 0], prediction: 0 },
  uro: { title: "Urobilinogen", value: "0", color: [0, 0, 0], prediction: 0 },
  pro: { title: "Protein", value: "0", color: [0, 0, 0], prediction: 0 },
  ph: { title: "pH", value: "0", color: [0, 0, 0], prediction: 0 },
  blo: { title: "Blood", value: "0", color: [0, 0, 0], prediction: 0 },
  sg: {
    title: "Specific Gravity",
    value: "0",
    color: [0, 0, 0],
    prediction: 0,
  },
  ket: { title: "Ketone", value: "0", color: [0, 0, 0], prediction: 0 },
  bil: { title: "Bilirubin", value: "0", color: [0, 0, 0], prediction: 0 },
  glu: { title: "Glucose", value: "0", color: [0, 0, 0], prediction: 0 },
};

export const SCAN_RESULT_STORAGE_KEY = "scanResultData";

export {
  ABNORMAL_DETAILS,
  DETAIL,
  PREFIX,
  SUFFIX,
  NUMERIC_MEASUREMENT,
  NUMERIC_MEASUREMENT_UNITS,
};
