import { Button } from "@/components/ui/button";
import { ChangeEvent, ReactNode } from "react";

interface IProps {
  disabled?: boolean;
  children: ReactNode;
  className?: string;
  variant?: "outline" | "link" | "default";
  onChangePicture?: (event: ChangeEvent<HTMLInputElement>) => void;
  onClick?: any;
}

function TakePhotoButton({
  disabled = false,
  children,
  className = "",
  variant = "outline",
  onChangePicture = () => {},
  onClick = () => {},
}: IProps) {
  const id = "take-photo";

  return (
    <label className={className} htmlFor={id}>
      <Button
        asChild
        className="w-full"
        variant={variant === "default" ? undefined : variant}
        disabled={disabled}
        onClick={onClick}
      >
        {disabled ? (
          <button>{children}</button>
        ) : (
          <span className="cursor-pointer">{children}</span>
        )}
      </Button>
      <input
        id={id}
        type="file"
        accept="image/*"
        className="hidden"
        hidden
        capture
        onChange={onChangePicture}
        disabled={disabled}
      />
    </label>
  );
}

export default TakePhotoButton;
