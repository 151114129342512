export default function Infection() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="6" stroke="#096467" strokeWidth="2" />
      <path
        d="M12 6V3"
        stroke="#096467"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M10 3H14"
        stroke="#096467"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M12 18L12 21"
        stroke="#096467"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M14 21L10 21"
        stroke="#096467"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M18 12L21 12"
        stroke="#096467"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M21 10L21 14"
        stroke="#096467"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M6 12L3 12"
        stroke="#096467"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M3 14L3 10"
        stroke="#096467"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M16.2426 7.75737L18.364 5.63605"
        stroke="#096467"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M16.9498 4.2218L19.7782 7.05023"
        stroke="#096467"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M7.75737 16.2426L5.63605 18.364"
        stroke="#096467"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M7.05023 19.7782L4.22181 16.9498"
        stroke="#096467"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M16.2426 16.2426L18.364 18.364"
        stroke="#096467"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M19.7782 16.9498L16.9498 19.7782"
        stroke="#096467"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M7.75737 7.75737L5.63605 5.63605"
        stroke="#096467"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M4.2218 7.05023L7.05023 4.22181"
        stroke="#096467"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
