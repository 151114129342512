import { ReactNode } from "react";

export enum ScanMediaType {
  Image = "image",
  Video = "video",
}

export interface IScanStepMedia {
  type: ScanMediaType;
  src: any;
}

interface ScanMediaFunctionProps {
  stipPictureFile?: File;
}

type ScanMediaFunction = ({
  stipPictureFile,
}: ScanMediaFunctionProps) => IScanStepMedia;

export interface IScanStep {
  id: string;
  title: string;
  description?: string;
  media?: IScanStepMedia | ScanMediaFunction;
  btnLabel?: string;
  startTimer?: boolean;
  takePicture?: boolean;
  cameraStep?: boolean;
  metadata?: any;
}

export interface IInstructionsStep {
  id: string;
  headerTitle?: string;
  title: string | (({ timerTime }: { timerTime?: number }) => ReactNode);
  description?: ({ timerTime }: { timerTime?: number }) => ReactNode;
  media?: IScanStepMedia;
  btnLabel?: string;
  metadata?: any;
}
