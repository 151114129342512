import { ReactNode } from "react";
import useMeasure from "react-use-measure";

interface IProps {
  children?: ReactNode;
}

function Header({ children }: IProps) {
  const [headerRef, headerDimensions] = useMeasure();

  return (
    <div style={{ height: headerDimensions.height }}>
      <div
        className="fixed top-0 left-0 w-full backdrop-blur-md z-50"
        ref={headerRef}
        style={{ background: "hsl(var(--background) / 60%)" }}
      >
        {children}
      </div>
    </div>
  );
}

export default Header;
