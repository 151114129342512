import { IScanStepMedia, ScanMediaType } from "@/types/Scan";

function Media({ media }: { media: IScanStepMedia }) {
  switch (media.type) {
    case ScanMediaType.Image:
      return (
        <img
          className="max-h-[55vh] mx-auto rounded-lg"
          src={media.src}
          alt="step_img"
        />
      );
    case ScanMediaType.Video:
      return (
        <video
          className="w-full h-100 rounded-lg"
          playsInline
          muted
          autoPlay
          preload="auto"
          loop
          style={{
            pointerEvents: "none",
          }}
        >
          <source src={media.src} type="video/mp4" />
        </video>
      );
    default:
      return null;
  }
}

export default Media;
