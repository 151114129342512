import { cn } from "@/lib/utils";
import Timer from "./Timer";

export default function TakePhotoTimer({
  time,
  className,
}: {
  time: number;
  className?: string;
}) {
  const timeInSeconds = time / 1000;

  return (
    <div
      className={cn(
        "flex items-center space-x-3 justify-between px-4 py-4 rounded-lg bg-ribbon-slate-100",
        "[&.timer-expire]:bg-ribbon-rose-300 transition",
        timeInSeconds <= 10 ? "timer-expire" : undefined,
        className
      )}
    >
      <div className="text-ribbon-teal-900 [.timer-expire_&]:text-ribbon-rose-700 transition">
        Time remaining to take photo
      </div>
      <Timer
        time={time}
        hideIcon={true}
        className="min-w-18 bg-white text-ribbon-teal-700 [&.timer-expire]:bg-white [&.timer-expire_*]:text-ribbon-rose-700"
      />
    </div>
  );
}
