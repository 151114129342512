export default function Hydration() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 15C18 18.3137 15.3137 21 12 21C8.68629 21 6 18.3137 6 15C6 9.90123 9.07904 6.06252 11.0777 4.11675C11.608 3.60054 12.392 3.60054 12.9223 4.11675C14.921 6.06252 18 9.90123 18 15Z"
        stroke="#096467"
        strokeWidth="2"
      />
      <path
        d="M14 11C14.3333 11.5 15 13 15 15"
        stroke="#096467"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
