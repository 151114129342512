import { IIconProps } from "@/types/common";

export default function Alert({ size = 24 }: IIconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.2517 5.14697C11.0137 3.77535 12.9863 3.77535 13.7483 5.14697L20.3493 17.0287C21.0899 18.3618 20.1259 20 18.601 20H5.39903C3.87406 20 2.91012 18.3618 3.65071 17.0287L10.2517 5.14697Z"
        fill="currentColor"
      />
      <circle cx="12" cy="16.5" r="1.5" fill="white" />
      <path
        d="M12 8L12 13"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
