import { Drawer, DrawerContent, DrawerTitle } from "@/components/ui/drawer";
import { Button } from "@/components/ui/button";
import FFNotSupportedImg from "@/assets/img/ff-not-supported.png";
import { useCallback } from "react";
import { toast } from "sonner";

interface IProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export default function FirefoxNotSupportedModal({
  open,
  onOpenChange,
}: IProps) {
  const handleCopyUrl = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(window.location.href);
      toast.success("URL copied to clipboard");
    } catch (error: any) {
      toast.error(error.message || "Failed to copy URL");
      console.error(error);
    }
  }, []);

  return (
    <Drawer
      open={open}
      onOpenChange={onOpenChange}
      modal={true}
      dismissible={false}
    >
      <DrawerContent>
        <div className="w-full max-w-screen-sm mx-auto px-5 py-5">
          <img
            src={FFNotSupportedImg}
            alt="Firefox not yet supported"
            className="w-[115px] mb-6 mx-auto"
          />
          <DrawerTitle asChild className="text-2xl">
            <h2 className="text-center font-semibold mb-3">
              Firefox is not yet supported
            </h2>
          </DrawerTitle>
          <div className="text-center mb-6">
            <p>
              Please try opening the web app in another browser, such as Chrome
              or Safari.
            </p>
          </div>
          <div className="flex flex-col items-stretch space-y-4">
            <Button variant="outline" onClick={handleCopyUrl}>
              Copy URL
            </Button>
          </div>
        </div>
      </DrawerContent>
    </Drawer>
  );
}
