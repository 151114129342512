import AppLogo2 from "@/components/AppLogo/AppLogo2";
import Cart from "@/components/icons/Cart";
import { Button } from "@/components/ui/button";
import { COLORS } from "@/utils/colors";
import { ORDER_TEST_KITS_URL, QUERY_KEY } from "@/utils/variables";
import BgImage from "@/assets/img/home6.jpg";
import { ArrowRight } from "lucide-react";
import { Link } from "react-router-dom";
import { Session, User } from "@supabase/supabase-js";
import { useQuery } from "@tanstack/react-query";
import { supabaseService } from "@/services/supabaseService";
import { useMemo } from "react";
import { DateTime } from "luxon";
import { checkHasAbnormalResults } from "@/utils/scanUtils";
import ResultsHistoryCard from "@/components/ResultsHistoryCard";
import OverrideTheme from "@/components/OverrideTheme";
import Spinner from "@/components/Spinner";
import { User as UserIcon } from "lucide-react";

interface IProps {
  session: Session;
}

export default function AuthenticatedHomePage({ session }: IProps) {
  const supabase = supabaseService.getClient();
  const user = session.user;

  const resultsQuery = useQuery({
    queryKey: [QUERY_KEY.scanResults],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("UserScan")
        .select("*, Scan (*, Result (*))")
        .eq("user_uid", session.user.id)
        .limit(10)
        .order("created_at", { ascending: false });

      if (error) {
        throw error;
      } else {
        return data;
      }
    },
    enabled: !!session?.user.id,
  });

  const recentTests = useMemo(() => {
    if (resultsQuery.isSuccess) {
      return resultsQuery.data
        .filter((result) => !!(result["Scan"] && result["Scan"]["Result"][0]))
        .map((result) => {
          const locale = "en";
          const scanDate = DateTime.fromISO(
            result["Scan"]["Result"][0].created_at
          ).setLocale(locale);
          const localDate = DateTime.local().setLocale(locale);
          const diff = localDate.diff(scanDate, ["days"]);
          const diffInDays = Math.floor(diff.days);
          let dateText = scanDate.toFormat("DDD t");

          switch (true) {
            case diffInDays === 0:
              dateText = `Today at ${scanDate.toFormat("t")}`;
              break;
            case diffInDays <= 30:
              dateText = `${diffInDays} day${diffInDays === 1 ? "" : "s"} ago`;
              break;
            default:
              break;
          }

          const hasAbnormalResults = checkHasAbnormalResults(
            result["Scan"]["Result"][0].color_data.predictions
          );
          const data = {
            dateText,
            resultData: result,
            hasAbnormalResults,
          };
          return data;
        });
    }

    return [];
  }, [resultsQuery]);

  return (
    <div className="w-full max-w-screen-sm mx-auto px-5 pb-5">
      <div className="flex items-center justify-between py-7">
        <AppLogo2 width={106} height={24} color={COLORS.teal["900"]} />
        {user && (
          <Link to="/profile">
            <UserAvatar user={user} />
          </Link>
        )}
      </div>
      <div
        className="rounded-lg text-white bg-blend-overlay bg-cover p-5 mb-6"
        style={{
          backgroundImage: `url(${BgImage})`,
          backgroundColor: "hsla(182, 41%, 14%, 0.5)",
        }}
      >
        <h2 className="text-2xl font-bold mb-3">The Ribbon Checkup™️️</h2>
        <p className="mb-10 max-w-[236px]">
          The Ribbon Checkup™ is an easy to use at-home urine test that allows
          anyone to get a daily health update in few minutes.
        </p>
        <div className="space-y-4 flex flex-col items-stretch">
          <Button
            variant="link"
            asChild
            className="space-x-3 text-white font-medium"
          >
            <a href={ORDER_TEST_KITS_URL} target="_blank" rel="noreferrer">
              <Cart />
              <span>Order Test Kits</span>
            </a>
          </Button>
          <Button variant="secondary" className="font-medium" asChild>
            <Link to="/tutorial">
              <span>Start your checkup</span>
              <ArrowRight className="ml-3" />
            </Link>
          </Button>
        </div>
      </div>
      <div>
        <div className="flex items-center justify-between mb-2">
          <h3>Recent Tests</h3>
          <Button variant="link" asChild className="font-bold px-1">
            <Link to="test-history">View History</Link>
          </Button>
        </div>
        {resultsQuery.isLoading ? (
          <>
            <Spinner />
          </>
        ) : (
          <>
            {recentTests.length === 0 ? (
              <div className="p-8 flex flex-col items-center justify-center bg-ribbon-slate-200 rounded-md">
                <h3 className="font-bold text-center mb-4">No tests yet!</h3>
                <p className="text-center">
                  Recent results will appear here once you have completed a
                  test.
                </p>
              </div>
            ) : (
              <div className="space-y-4">
                {recentTests.map((item) => (
                  <ResultsHistoryCard
                    key={item.resultData.id}
                    id={item.resultData.id}
                    hasAbnormalResults={item.hasAbnormalResults}
                    dateText={item.dateText}
                  />
                ))}
              </div>
            )}
          </>
        )}
      </div>
      <OverrideTheme background="182 14% 96%" />
    </div>
  );
}

function UserAvatar({ user }: { user: User }) {
  const firstName = user.user_metadata?.first_name || "";

  return (
    <div className="rounded-full w-[44px] h-[44px] flex flex-col items-center justify-center border-2 border-ribbon-teal-900">
      {firstName.length > 0 ? (
        <span className="text-uppercase text-[20px]">
          {firstName.split("")[0]}
        </span>
      ) : (
        <UserIcon size={20} />
      )}
    </div>
  );
}
