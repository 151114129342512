import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import Header from "@/components/Header";
import { ChevronLeft } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  ChangeEvent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSpring, animated, useSpringRef } from "@react-spring/web";
import Media from "./Media";
import ButtonsContainer from "@/components/ButtonsContainer";
import { getNumericEnvVariable } from "@/utils/common";
import useTimer, { TimerStatus } from "@/hooks/useTimer";
import AlertMP3 from "@/assets/audio/alert.mp3";
import { cn } from "@/lib/utils";
import { Drawer, DrawerContent, DrawerTitle } from "@/components/ui/drawer";
import TakePhotoButton from "./TakePhotoButton";
import PermissionsIosImage from "@/assets/img/permissions-ios.png";
import { useTestContext } from "@/contexts/testContext";
import OverrideTheme from "@/components/OverrideTheme";
import {
  DIP_STEP_ID,
  TAKE_PHOTO_STEP_ID,
  WAIT_FOR_DEVELOP_STEP_ID,
} from "@/utils/scanUtils";
import TakePhotoTimer from "./TakePhotoTimer";

import { TEST_INSTRUCTIONS_STEPS as STEPS } from "@/utils/scanUtils";

const audioElement = new Audio(AlertMP3);
audioElement.loop = true;

const developTimerLength = getNumericEnvVariable(
  process.env.REACT_APP_DEVELOP_STRIP_TIMER_LENGTH,
  95
);
const takePhotoTimerLength = getNumericEnvVariable(
  process.env.REACT_APP_TAKE_PHOTO_TIMER_LENGTH,
  60
);
const showPhotoTimerDialogThreshold = 15;

function Instructions() {
  const { stepId } = useParams();
  const navigate = useNavigate();
  const animationRef = useSpringRef();
  const developTimer = useTimer({ time: developTimerLength });
  const takePhotoTimer = useTimer({ time: takePhotoTimerLength });
  const [openPhotoTimerDialog, setOpenPhotoTimerDialog] = useState(false);
  const [shownPhotoTimerExpireDialog, setShownPhotoTimerExpireDialog] =
    useState(false);
  const [shownPhotoTimerRunOutDialog, setShownPhotoTimerRunOutDialog] =
    useState(false);
  const [shownHavingTroublesDialog, setShownHavingTroublesDialog] =
    useState(false);
  const [takePhotoAttemptsCount, setTakePhotoAttemptsCount] = useState(0);
  const [openHavingTroublesDialog, setOpenHavingTroublesDialog] =
    useState(false);
  const { analyzeStripPhoto, stripDevelopmentStart, setStripDevelopmentStart } =
    useTestContext();

  const isPhotoTimerOutOfTime = takePhotoTimer.status === TimerStatus.Expired;
  const photoTimerTimeInSeconds = takePhotoTimer.time / 1000;

  const [fadeAnimation] = useSpring(
    () => ({
      ref: animationRef,
    }),
    []
  );

  const { step, stepIndex } = useMemo(() => {
    const stepIndex = STEPS.findIndex((step) => step.id === stepId);
    const step = stepIndex >= 0 ? STEPS[stepIndex] : undefined;
    return {
      step,
      stepIndex,
    };
  }, [stepId]);

  // const cancel = useCallback(() => {
  //   navigate("/", { replace: true });
  // }, [navigate]);

  const navigateBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const goToNextStep = useCallback(() => {
    const nextStep = STEPS[stepIndex + 1];

    if (step?.id === DIP_STEP_ID && stripDevelopmentStart === undefined) {
      setStripDevelopmentStart(Date.now());
    }

    if (nextStep) {
      navigate(`/test/instructions/${nextStep.id}`);
    }
  }, [
    navigate,
    setStripDevelopmentStart,
    step,
    stepIndex,
    stripDevelopmentStart,
  ]);

  const stopAlarmSound = useCallback(() => {
    try {
      audioElement.pause();
    } catch (error) {
      console.error(error);
    }
  }, []);

  const playAlarmSound = useCallback(() => {
    try {
      audioElement.play();
    } catch (error) {
      console.error(error);
    }
    setTimeout(stopAlarmSound, 6000);
  }, [stopAlarmSound]);

  const handleDevelopTimerExpired = useCallback(() => {
    playAlarmSound();
    navigate(`/test/instructions/${TAKE_PHOTO_STEP_ID}`, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, playAlarmSound]);

  const onClickTakePhoto = useCallback(() => {
    setTakePhotoAttemptsCount((current) => current + 1);
  }, []);

  const handleWindowFocus = useCallback(() => {
    if (takePhotoAttemptsCount > 1 && !shownHavingTroublesDialog) {
      setTimeout(() => {
        setOpenHavingTroublesDialog(true);
        setShownHavingTroublesDialog(true);
      }, 1000);
    }
  }, [shownHavingTroublesDialog, takePhotoAttemptsCount]);

  const handleChangeStripPhoto = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        analyzeStripPhoto(file);
        navigate("/test/analyze-strip/", {
          replace: true,
          state: { stripPhoto: file },
        });
      }
    },
    [analyzeStripPhoto, navigate]
  );

  const renderTakePhotoButton = useCallback(
    (children: ReactNode = "Take Photo", props: { [x: string]: any } = {}) => {
      return (
        <TakePhotoButton
          className="grow"
          variant="default"
          onClick={onClickTakePhoto}
          onChangePicture={handleChangeStripPhoto}
          disabled={isPhotoTimerOutOfTime}
          {...props}
        >
          {children}
        </TakePhotoButton>
      );
    },
    [handleChangeStripPhoto, isPhotoTimerOutOfTime, onClickTakePhoto]
  );

  useEffect(() => {
    window.addEventListener("focus", handleWindowFocus);
    return () => window.removeEventListener("focus", handleWindowFocus);
  }, [handleWindowFocus]);

  useEffect(() => {
    animationRef.start({
      from: { opacity: 0 },
      to: { opacity: 1 },
      config: {
        friction: 20,
        clamp: true,
      },
    });
  }, [step, animationRef]);

  useEffect(() => {
    if (step?.metadata?.startTimer) developTimer.start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  useEffect(() => {
    if (step?.metadata?.takePhotoStep) takePhotoTimer.start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  useEffect(() => {
    if (developTimer.status === TimerStatus.Expired)
      handleDevelopTimerExpired();
  }, [handleDevelopTimerExpired, developTimer.status]);

  useEffect(() => {
    if (
      photoTimerTimeInSeconds <= showPhotoTimerDialogThreshold &&
      takePhotoTimer.status !== TimerStatus.Stopped
    ) {
      if (
        takePhotoTimer.status === TimerStatus.Running &&
        !shownPhotoTimerExpireDialog
      ) {
        setOpenPhotoTimerDialog(true);
        setShownPhotoTimerExpireDialog(true);
      } else if (
        takePhotoTimer.status === TimerStatus.Expired &&
        !shownPhotoTimerRunOutDialog
      ) {
        setOpenPhotoTimerDialog(true);
        setShownPhotoTimerExpireDialog(true);
        setShownPhotoTimerRunOutDialog(true);
      }
    }
  }, [
    photoTimerTimeInSeconds,
    shownPhotoTimerExpireDialog,
    shownPhotoTimerRunOutDialog,
    takePhotoTimer.status,
  ]);

  if (!stepId)
    return <Navigate to={"/test/instructions/" + STEPS[0].id} replace />;

  return (
    <>
      <div className="mx-auto max-w-screen-sm w-full grow">
        <Header>
          <div className="mx-auto max-w-screen-sm px-6 py-5 grid grid-cols-header gap-4 items-center">
            <Button variant="ghost" size="icon" onClick={navigateBack}>
              <ChevronLeft size={30} />
            </Button>
            <div className="flex flex-col items-center justify-center">
              {stepIndex >= 0 && (
                <div>
                  Step {stepIndex + 1} of {STEPS.length}
                </div>
              )}
              <div className="font-bold">{step && step.headerTitle}</div>
            </div>
            {/*<Button variant="ghost" size="icon" onClick={cancel}>
              <X size={30} />
            </Button>*/}
          </div>
        </Header>
        <div className="px-6">
          {step && (
            <>
              <animated.div style={fadeAnimation}>
                {step.media && (
                  <div className="relative">
                    <Media media={step.media} />
                  </div>
                )}
                <div className="my-6 w-full">
                  <h2 className="mb-4 text-2.5xl font-medium">
                    {typeof step.title === "string"
                      ? step.title
                      : step.title({ timerTime: developTimer.time })}
                  </h2>
                  {step.description &&
                    step.description({ timerTime: takePhotoTimer.time })}
                </div>
              </animated.div>
            </>
          )}
        </div>
        <ButtonsContainer>
          {step && (
            <>
              {step.metadata?.takePhotoStep ? (
                <>{renderTakePhotoButton("Take Photo")}</>
              ) : (
                <>
                  <Button
                    className="grow"
                    onClick={goToNextStep}
                    disabled={
                      step.id === WAIT_FOR_DEVELOP_STEP_ID &&
                      developTimer.status === TimerStatus.Running
                    }
                  >
                    {step.btnLabel}
                  </Button>
                </>
              )}
            </>
          )}
        </ButtonsContainer>
      </div>
      <Drawer
        open={openPhotoTimerDialog}
        onOpenChange={setOpenPhotoTimerDialog}
      >
        <DrawerContent>
          <div className="w-full max-w-screen-sm mx-auto px-5 py-5">
            <TakePhotoTimer time={takePhotoTimer.time} className="mb-6" />
            <DrawerTitle asChild className="text-2xl">
              <h2 className="text-center font-semibold mb-3">
                {isPhotoTimerOutOfTime
                  ? "Uh oh! You ran out of time to take a photo of your test"
                  : "You are running out of time to take a photo"}
              </h2>
            </DrawerTitle>
            <div className="text-center mb-6">
              <p>
                In order to provide an accurate result, the test strip can only
                be interpreted within 2 minutes after it has developed.
              </p>
              {isPhotoTimerOutOfTime && (
                <p>Please try testing again with a new test kit.</p>
              )}
            </div>
            <div className="flex flex-col items-stretch space-y-4">
              {isPhotoTimerOutOfTime ? (
                <>
                  <Button asChild>
                    <Link to="/">Go Home</Link>
                  </Button>
                  {/*<Button variant="outline">Chat With Support</Button>*/}
                </>
              ) : (
                <>
                  {renderTakePhotoButton("Take Photo")}
                  <Button
                    variant="outline"
                    className={cn(
                      step?.id === TAKE_PHOTO_STEP_ID &&
                        "hover:text-primary-foreground"
                    )}
                    onClick={() => setOpenPhotoTimerDialog(false)}
                  >
                    Go Back
                  </Button>
                </>
              )}
            </div>
          </div>
        </DrawerContent>
      </Drawer>
      <Drawer
        open={openHavingTroublesDialog}
        onOpenChange={setOpenHavingTroublesDialog}
      >
        <DrawerContent>
          <div className="w-full max-w-screen-sm mx-auto px-5 py-5">
            <img
              src={PermissionsIosImage}
              alt="Permissions"
              className="w-full mb-6 rounded-lg max-w-[400px] mx-auto"
            />
            <DrawerTitle asChild className="text-2xl">
              <h2 className="text-center font-semibold mb-3">
                Having trouble taking a picture? You may need to enable camera
                permissions.
              </h2>
            </DrawerTitle>
            <div className="text-center mb-6">
              <ol type="1" className="list-decimal flex flex-col items-center">
                <li>Open the ‘Settings’ app on your phone.</li>
                <li>Open ‘Privacy & Security’, then ‘Camera’.</li>
                <li>Toggle the switch next to your browser on.</li>
              </ol>
            </div>
            <div className="flex flex-col items-stretch space-y-4">
              <Button onClick={() => setOpenHavingTroublesDialog(false)}>
                Got it
              </Button>
            </div>
          </div>
        </DrawerContent>
      </Drawer>
      {step?.id === TAKE_PHOTO_STEP_ID && (
        <OverrideTheme
          background="182 80% 16%"
          foreground="0 0% 100%"
          primary="182 83% 91%"
          primaryForeground="182 80% 16%"
        />
      )}
    </>
  );
}

export default Instructions;
