import { useLoaderData } from "react-router-dom";
import AuthenticatedHomePage from "./Authenticated";
import GuestHomePage from "./Guest";

export default function Home() {
  const session: any = useLoaderData();
  const isAutheticated = !!session?.user;

  if (isAutheticated) {
    return <AuthenticatedHomePage session={session} />;
  } else {
    return <GuestHomePage />;
  }
}
