import {
  Route,
  Navigate,
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  useLoaderData,
} from "react-router-dom";
// import posthog from "posthog-js";
import Home from "../pages/Home/Home";
import Tutorial from "../pages/Tutorial";
import { AppContextProvider } from "../contexts/appContext";
import AppLayout from "../components/AppLayout";
import { supabaseService } from "../services/supabaseService";
import ResultsPage from "../pages/ResultsPage";
import SingleResultPage from "../pages/SingleResultPage";
import Instructions from "../pages/Scan/Instructions";
import { TestContextProvider } from "../contexts/testContext";
import AnalyzeStrip from "../pages/Scan/AnalyzeStrip";
import ResultPage from "../pages/Scan/ResultPage";
import Auth from "../pages/Auth/Auth";
import ProfilePage from "../pages/ProfilePage";
import PreloadScanAssets from "./PreloadScanAssets";

const sessionLoader = async () => {
  const {
    data: { session },
  } = await supabaseService.getClient().auth.getSession();

  return session;
};

// const AuthRoutes = () => {
//   const session = useLoaderData();
//   return session ? <Navigate to="/" replace /> : <Outlet />;
// };

const ProtectedRoutes = () => {
  const session = useLoaderData();
  return !session ? <Navigate to="/" replace /> : <Outlet />;
};

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/"
      loader={sessionLoader}
      element={
        <AppContextProvider>
          <PreloadScanAssets>
            <AppLayout>
              <Outlet />
            </AppLayout>
          </PreloadScanAssets>
        </AppContextProvider>
      }
    >
      <Route path="/" element={<Home />} loader={sessionLoader} />
      <Route path="/auth/:stepId?" element={<Auth />} />
      <Route path="/tutorial/:stepId?" element={<Tutorial />} />
      <Route element={<ProtectedRoutes />} loader={sessionLoader}>
        <Route path="/test-history" element={<ResultsPage />} />
        <Route path="/test-history/:id" element={<SingleResultPage />} />
        <Route
          path="/profile"
          element={<ProfilePage />}
          loader={sessionLoader}
        />
      </Route>
      <Route
        path="/test"
        element={
          <TestContextProvider>
            <Outlet />
          </TestContextProvider>
        }
      >
        <Route path="instructions/:stepId?" element={<Instructions />} />
        <Route path="analyze-strip" element={<AnalyzeStrip />} />
        <Route path="result" element={<ResultPage />} />
        <Route
          path="*"
          index
          element={<Navigate to="/test/instructions" replace />}
        />
      </Route>
      <Route path="*" index element={<Navigate to="/" replace />} />
    </Route>
  )
);
