import { Button } from "@/components/ui/button";
import { Drawer, DrawerContent, DrawerTitle } from "./ui/drawer";
import { useCallback, useState } from "react";
import { supabaseService } from "@/services/supabaseService";
import { DateTime } from "luxon";
import fileDownload from "js-file-download";
import axios from "axios";

const supabase = supabaseService.getClient();

interface IProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  testPhoto?: string | File;
}

export default function TalkToDoctorDrawer({
  open,
  onOpenChange,
  testPhoto,
}: IProps) {
  const [isLoading, setIsLoading] = useState(false);

  const handleDownloadTestPhoto = useCallback(async () => {
    try {
      setIsLoading(true);
      const downloadFileName =
        "Ribbon_Checkup_Test_Photo_" +
        DateTime.local().setLocale("en-US").toFormat("D_t") +
        ".png";

      if (typeof testPhoto === "string" && testPhoto.startsWith("scans/")) {
        const { data, error } = await supabase.storage
          .from("scans")
          .createSignedUrl(testPhoto.replace(/^scans\//, ""), 3600);
        if (error) throw error;

        await axios
          .get(data.signedUrl, {
            responseType: "blob",
          })
          .then((res) => {
            fileDownload(res.data, downloadFileName);
          });
      } else if (testPhoto instanceof File) {
        fileDownload(testPhoto, downloadFileName);
      } else {
        throw new Error("Error");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [testPhoto]);

  return (
    <Drawer open={open} onOpenChange={onOpenChange}>
      <DrawerContent>
        <div className="w-full max-w-screen-sm mx-auto px-5 pb-5">
          <DrawerTitle className="text-center font-bold mb-6">
            Talk to a doctor
          </DrawerTitle>
          <p className="text-center mb-5">
            In order to provide treatment, your telehealth provider will require
            a photo of your test at the time it was performed.
          </p>
          <div className="flex flex-col space-y-3">
            {testPhoto && (
              <Button
                variant="outline"
                className="w-full space-x-3"
                onClick={handleDownloadTestPhoto}
                disabled={isLoading}
              >
                <span>Download Test Photo</span>
              </Button>
            )}
            <Button asChild className="w-full space-x-3">
              <a
                href={process.env.REACT_APP_UTI_TREATMENT_SERVICE_LINK || "#"}
                target="_blank"
                rel="noreferrer"
              >
                <span>Talk to a doctor</span>
              </a>
            </Button>
          </div>
        </div>
      </DrawerContent>
    </Drawer>
  );
}
