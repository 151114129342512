import { AuthError } from "@supabase/supabase-js";
import { isNumber, padStart } from "lodash";
import { toast } from "sonner";
import { PREFIX } from "./variables";
import { EPredictionsParameterStatus } from "@/types/common";
import { supabaseService } from "@/services/supabaseService";

export function handleOtpSendError(error: AuthError) {
  const { message, code } = error;
  let notificationMessage = "Error";

  switch (code) {
    case "otp_disabled":
      if (message.startsWith("Signups not allowed"))
        notificationMessage =
          "No account associated with your phone number. Please, sign up";
      break;
    case "sms_send_failed":
      if (message.includes("Invalid 'To' Phone Number"))
        notificationMessage = "Failed to send auth code to your phone number";
      break;
    default:
      break;
  }

  toast.error(notificationMessage);
  console.error(error);
}

export function getNumericEnvVariable(
  value: string | undefined,
  defaultValue = 120
): number {
  const asNumber = Number(value);
  const timerLengthInSeconds =
    isNumber(asNumber) && isFinite(asNumber) ? asNumber : defaultValue;
  return timerLengthInSeconds;
}

export function sanitizePhoneNumber(phoneNumber: string) {
  return phoneNumber.replace(/\D/g, "");
}

export function formatTimerTime(timeInSeconds: number) {
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = timeInSeconds - minutes * 60;
  const minutesStr = padStart(minutes.toString(), 2, "0");
  const secondsStr = padStart(seconds.toString(), 2, "0");

  return `${minutesStr}:${secondsStr}`;
}

export function getPredictionsParameterStatus(
  predictionsParameterName: string,
  value: number
) {
  const max = Math.max(PREFIX[predictionsParameterName].length - 1, 0);
  let mediumThreshold = Math.min(1, max);
  let highThreshold = max;

  switch (predictionsParameterName) {
    case "sg":
      mediumThreshold = max;
      break;
    case "ph":
      highThreshold = max + 1;
      mediumThreshold = highThreshold;
      break;
    case "uro":
      mediumThreshold = 3;
      break;
    case "bil":
      mediumThreshold = 2;
      break;
    default:
      break;
  }

  const status =
    value >= highThreshold
      ? EPredictionsParameterStatus.High
      : value >= mediumThreshold && value < highThreshold
      ? EPredictionsParameterStatus.Medium
      : EPredictionsParameterStatus.Normal;
  return status;
}

export const getCurrentUserLegalSex = async () => {
  const {
    data: { user },
  } = await supabaseService.getClient().auth.getUser();
  const stroredData = localStorage.getItem("user_legal_sex");

  if (user?.user_metadata?.legal_sex) {
    return user.user_metadata.legal_sex;
  } else if (stroredData) {
    return stroredData;
  } else {
    return undefined;
  }
};
