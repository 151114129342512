export default function PH() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.06729 16.5C2.38456 15.1474 2 13.6186 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 13.6186 21.6154 15.1474 20.9327 16.5"
        stroke="#096467"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle
        cx="12"
        cy="12"
        r="1.5"
        stroke="#096467"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M11.4487 7.68377C11.274 7.15983 10.7077 6.87667 10.1838 7.05132C9.65983 7.22596 9.37667 7.79228 9.55132 8.31623L11.4487 7.68377ZM12.4487 10.6838L11.4487 7.68377L9.55132 8.31623L10.5513 11.3162L12.4487 10.6838Z"
        fill="#096467"
      />
      <path
        d="M12 2V5"
        stroke="#096467"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M18.5 5.5L16.5 7.5"
        stroke="#096467"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M18.5 5.5L16.5 7.5"
        stroke="#096467"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M22 12L19 12"
        stroke="#096467"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M5.5 5.5L7.5 7.5"
        stroke="#096467"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M2 12L5 12"
        stroke="#096467"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
