import { memo } from "react";
import { Global } from "@emotion/react";

interface IProps {
  background?: string;
  foreground?: string;
  primary?: string;
  primaryForeground?: string;
}

function OverrideThemeComponent({
  background,
  foreground,
  primary,
  primaryForeground,
}: IProps) {
  return (
    <Global
      styles={{
        ":root": {
          "--background": background,
          "--foreground": foreground,
          "--primary": primary,
          "--primary-foreground": primaryForeground,
        },
      }}
    />
  );
}

const OverrideTheme = memo(OverrideThemeComponent);

export default OverrideTheme;
