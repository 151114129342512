export default function Liver() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1589_10268)">
        <path
          d="M19.6481 3H9C5.68629 3 3 5.68629 3 9V16.1716C3 17.9534 5.15429 18.8457 6.41422 17.5858L8.12132 15.8787C8.68393 15.3161 9.44699 15 10.2426 15H13C15.7614 15 18 12.7614 18 10V9.8942C18 8.73708 18.6655 7.68311 19.7102 7.18562L20.508 6.80572C22.4323 5.88937 21.7795 3 19.6481 3Z"
          stroke="#096467"
          strokeWidth="2"
        />
        <path
          d="M7 10H9C11.2091 10 13 11.7909 13 14V21"
          stroke="#096467"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M15 7V7C13.8954 7 13 7.89543 13 9V17.5"
          stroke="#096467"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1589_10268">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
