import { cn } from "@/lib/utils";
import { IIconProps } from "@/types/common";

export default function History({ className }: IIconProps) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn(className)}
    >
      <g clip-path="url(#clip0_3629_22378)">
        <path
          d="M9.83398 4L2.83398 4C2.2817 4 1.83398 4.44772 1.83398 5V21C1.83398 21.5523 2.2817 22 2.83398 22H13.834C14.3863 22 14.834 21.5523 14.834 21V17"
          stroke="#096467"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <circle
          cx="17.834"
          cy="8"
          r="6"
          stroke="#096467"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M5.83398 8H7.83398"
          stroke="#096467"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M5.83398 14L9.83398 14"
          stroke="#096467"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M5.83398 18H10.834"
          stroke="#096467"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M17.834 5.5V7.58579C17.834 7.851 17.9393 8.10536 18.1269 8.29289L19.334 9.5"
          stroke="#096467"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3629_22378">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.833984)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
