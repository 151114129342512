import {
  ABNORMAL_DETAILS,
  PARAMETER_TITLE,
  PREFIX,
  STATE_LIST,
  SUFFIX,
  NUMERIC_MEASUREMENT,
  NUMERIC_MEASUREMENT_UNITS,
} from "@/utils/variables";
import { useMemo, useState } from "react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "./ui/accordion";
import PredictionRange from "./PredictionRange";
import { isPredictionNormal } from "@/utils/scanUtils";
import ResultStatusBadge from "./ResultStatusBadge";
import UTIAlert from "./UTIAlert";
import TalkToDoctorDrawer from "./TalkToDoctorDrawer";
import { cn } from "@/lib/utils";

interface IProps {
  data: {
    predictions: {
      [key: string]: number;
    };
    reagent_colors: {
      [key: string]: number[];
    };
  };
  testPhotoFileOrObjectPath?: string | File;
}

export default function ScanResult({
  data,
  testPhotoFileOrObjectPath,
}: IProps) {
  const [openTalkToDoctorModal, setOpenTalkToDoctorModal] = useState(false);
  const { stateData } = useMemo(() => {
    const { predictions } = data;

    const stateData = STATE_LIST.map((item) => {
      const { title, key } = item;
      const parameters = item.parameters.map((parameter) => {
        const predictionValue = predictions[parameter];
        const numericDetails =
          NUMERIC_MEASUREMENT[parameter][predictionValue] +
          " " +
          NUMERIC_MEASUREMENT_UNITS[parameter];
        const prefix = PREFIX[parameter][predictionValue];
        const suffix = SUFFIX[parameter][predictionValue];
        const state = `${prefix}${suffix}`;
        const details = ABNORMAL_DETAILS[parameter];
        const isNormal = isPredictionNormal(parameter, predictions);
        const title = PARAMETER_TITLE[parameter];

        return {
          numericDetails,
          state,
          details,
          predictionValue,
          title,
          isNormal,
          parameterName: parameter,
        };
      });

      return {
        title,
        key,
        parameters,
        Icon: item.Icon,
        statusLabels: item.statusLabels,
      };
    });

    return {
      stateData,
    };
  }, [data]);

  return (
    <>
      <div className="grow flex flex-col">
        <div>
          <Accordion
            type="multiple"
            className="w-full rounded-lg border bg-white border-gray-200"
            defaultValue={stateData.map(item => item.key)}
          >
            {stateData.map((item, index) => {
              const { key, title, parameters, Icon } = item;
              const isAbnormal = !!parameters.find((p) => p.isNormal === false);

              return (
                <AccordionItem
                  key={key}
                  value={key}
                  className={cn(
                    "[&[data-disabled]_.lucide-chevron-down]:invisible border-0",
                    index > 0 && "border-t"
                  )}
                  disabled={parameters.length === 0}
                >
                  <AccordionTrigger className="text-left !no-underline p-5 px-6">
                    <div className="flex grow pr-4 items-center">
                      <Icon />
                      <div className="font-normal text-xl ml-4 mr-auto">
                        {title}
                      </div>
                      <ResultStatusBadge
                        isAbnormal={isAbnormal}
                        normalLabel={item.statusLabels?.normal}
                        abnormalLabel={item.statusLabels?.abnormal}
                      />
                    </div>
                  </AccordionTrigger>
                  <AccordionContent className="text-base p-5 py-0 border-t border-gray-200">
                    {parameters.map((parameter, index) => {
                      const {
                        numericDetails,
                        details,
                        title,
                        state,
                        parameterName,
                        predictionValue,
                      } = parameter;

                      return (
                        <div
                          key={index}
                          className="[&:not(:last-child)]:border-b border-gray-100 py-5"
                        >
                          <div className="mb-4 flex items-center justify-between">
                            <div>
                              <div className="font-bold">{title}</div>
                              <div className="text-sm">{numericDetails}</div>
                            </div>
                            <div>
                              <PredictionRange
                                predictionParameterName={parameterName}
                                value={predictionValue}
                                label={state}
                              />
                            </div>
                          </div>
                          <div className="text-ribbon-slate-700">{details}</div>
                        </div>
                      );
                    })}
                    {item.key === "infection" && isAbnormal && (
                      <UTIAlert
                        className="mb-5"
                        onClickTalkToDoctor={() =>
                          setOpenTalkToDoctorModal(true)
                        }
                      />
                    )}
                  </AccordionContent>
                </AccordionItem>
              );
            })}
          </Accordion>
          <TalkToDoctorDrawer
            open={openTalkToDoctorModal}
            onOpenChange={setOpenTalkToDoctorModal}
            testPhoto={testPhotoFileOrObjectPath}
          />
        </div>
      </div>
    </>
  );
}
