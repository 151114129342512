import { IIconProps } from "@/types/common";

export default function Timer({ size = 24 }: IIconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="13" r="8" stroke="currentColor" strokeWidth="2" />
      <path
        d="M10 2L14 2"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 9V14L15 13"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.7071 7.70711C20.0976 7.31658 20.0976 6.68342 19.7071 6.29289C19.3166 5.90237 18.6834 5.90237 18.2929 6.29289L19.7071 7.70711ZM18.7071 8.70711L19.7071 7.70711L18.2929 6.29289L17.2929 7.29289L18.7071 8.70711Z"
        fill="currentColor"
      />
      <path
        d="M5.70711 6.29289C5.31658 5.90237 4.68342 5.90237 4.29289 6.29289C3.90237 6.68342 3.90237 7.31658 4.29289 7.70711L5.70711 6.29289ZM4.29289 7.70711L5.29289 8.70711L6.70711 7.29289L5.70711 6.29289L4.29289 7.70711Z"
        fill="currentColor"
      />
    </svg>
  );
}
