import { cn } from "@/lib/utils";
import React from "react";

interface IProps {
  timeString: string;
  className?: string;
}

export default function TimerString({ timeString, className }: IProps) {
  return (
    <span className={cn(className)}>
      {timeString.split("").map((char, index) => {
        if (/[0-9]/.test(char)) {
          return (
            <span className="inline-block min-w-[0.625em] text-center" key={index}>
              {char}
            </span>
          );
        }

        return <React.Fragment key={index}>{char}</React.Fragment>;
      })}
    </span>
  );
}
