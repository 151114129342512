export default function Glucose() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="4.5"
        cy="7.5"
        r="1.5"
        stroke="#096467"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle
        cx="19.5"
        cy="7.5"
        r="1.5"
        stroke="#096467"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle
        cx="4.5"
        cy="16.5"
        r="1.5"
        stroke="#096467"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle
        cx="19.5"
        cy="16.5"
        r="1.5"
        stroke="#096467"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle
        cx="12"
        cy="3.5"
        r="1.5"
        stroke="#096467"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle
        cx="12"
        cy="20.5"
        r="1.5"
        stroke="#096467"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M4 12V9"
        stroke="#096467"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M6 6L10 4"
        stroke="#096467"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M16 5L17.5 6"
        stroke="#096467"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M19.5 9L19.5 15"
        stroke="#096467"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M18 18L16 19"
        stroke="#096467"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M6 18L10 20"
        stroke="#096467"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
