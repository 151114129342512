export interface SplashColors {
  background: string;
  logo: string;
}

export interface ISignUpStep {
  id: string;
  name: string;
  component?: React.ElementType;
}

export interface ISignUpStepProps {
  onStepSubmit: (data: any) => void;
  isLoading?: boolean;
}

export interface Predictions {
  [key: string]: number;
}

export interface IAuthStepProps {
  onSubmit: (data: any) => void;
  isLoading?: boolean;
  stepsFormData: any;
}

export enum EAuthStep {
  PhoneNumber = "phone-number",
  Password = "password",
  PhoneVerify = "phone-verify",
  ForgotPassword = "forgot-password",
  CreatePassword = "create-password",
  ResetPassword = "reset-password",
  PersonalInfo = "personal-info",
  PrimaryUse = "primary-use",
}

export interface IIconProps {
  size?: number;
  className?: string;
}

export enum EPredictionsParameterStatus {
  Normal = "Normal",
  Medium = "Medium",
  High = "High",
}

export enum ELegalSexOptions {
  Male = "Male",
  Female = "Female",
}
