import { cn } from "@/lib/utils";
import { IIconProps } from "@/types/common";

export default function Cart({ className }: IIconProps) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn(className)}
    >
      <path
        d="M19.5 6H3.5L6.5 15H16.5L19.5 6ZM19.5 6L20.5 3H22.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5 15V18H6.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="7" cy="21.5" r="1.5" fill="currentColor" />
      <circle cx="16" cy="21.5" r="1.5" fill="currentColor" />
    </svg>
  );
}
