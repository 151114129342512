import Header from "@/components/Header";
import ScanResult from "@/components/ScanResult";
import { useAppContext } from "@/contexts/appContext";
import { supabaseService } from "@/services/supabaseService";
import { QUERY_KEY } from "@/utils/variables";
import { useQuery } from "@tanstack/react-query";
import { useCallback, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { ChevronLeft, Ellipsis, Mail } from "lucide-react";
import OverrideTheme from "@/components/OverrideTheme";
import { DateTime } from "luxon";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import CustomDropdownMenuItem from "@/components/CustomDropdownMenuItem";
import SendResultPdfModal from "@/components/SendResultPdfModal/SendResultPdfModal";

export default function SingleResultPage() {
  const { id } = useParams();
  const { session } = useAppContext();
  const supabase = supabaseService.getClient();
  const navigate = useNavigate();
  const [openEmailPdfModal, setOpenEmailPdfModal] = useState(false);

  const resultQuery = useQuery({
    queryKey: [QUERY_KEY.scanResults, id],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("UserScan")
        .select("*, Scan (*, Result (*))")
        .eq("user_uid", session.user.id)
        .eq("id", id)
        .single();

      if (error) {
        throw error;
      } else {
        return data;
      }
    },
  });

  const navigateBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const data = useMemo(() => {
    if (resultQuery.isSuccess && resultQuery.data?.Scan?.Result) {
      const resultData = resultQuery.data.Scan.Result;

      if (resultData[0]) {
        const color_data = resultData[0].color_data;
        const { card_colors, predictions, reagent_colors } = color_data;

        return {
          card_colors,
          predictions,
          reagent_colors,
          scan_uuid: resultQuery.data.Scan.scan_uuid,
          scan_order_no: resultQuery.data.Scan.scan_order_no,
          perspx_image: resultData[0].perspx_image,
          raw_image: resultQuery.data.Scan.raw_image,
        };
      }
    } else {
      return null;
    }
  }, [resultQuery]);

  return (
    <>
      <Header>
        <div className="mx-auto max-w-screen-sm px-6 py-5 grid grid-cols-header gap-4 items-center">
          <Button
            variant="ghost"
            size="icon"
            onClick={navigateBack}
            className="text-ribbon-teal-700"
          >
            <ChevronLeft size={30} />
          </Button>
          <div className="flex flex-col items-center justify-center">
            {resultQuery.isSuccess && (
              <div className="font-bold">
                {DateTime.fromISO(resultQuery.data.created_at)
                  .setLocale("en-US")
                  .toFormat("LLLL d, y")}
              </div>
            )}
          </div>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button size="icon">
                <Ellipsis />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              className="bg-ribbon-teal-700 text-white rounded-3xl p-2 min-w-[200px]"
              side="left"
              align="start"
            >
              <CustomDropdownMenuItem
                Icon={Mail}
                onClick={() => setOpenEmailPdfModal(true)}
              >
                Email Results
              </CustomDropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </Header>
      <div className="max-w-screen-sm w-full mx-auto px-5 pb-6">
        {data && (
          <div className="bg-white rounded-3xl shadow-lg">
            <ScanResult
              data={data}
              testPhotoFileOrObjectPath={data.raw_image}
            />
          </div>
        )}
      </div>
      <OverrideTheme background="182 14% 96%" />
      {data && (
        <SendResultPdfModal
          open={openEmailPdfModal}
          onOpenChange={setOpenEmailPdfModal}
          resultData={data}
        />
      )}
    </>
  );
}
